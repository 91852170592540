<template>
  <div>
    <a-card class="breadcrumb-card">
      <a-col :md="24" style="display: flex">
        <breadcrumb style="padding-top: 6px; padding-left: 16px" />
      </a-col>
    </a-card>
    <div class="csv-info">
      <div class="csv-input">
        <h1>Input:</h1>
        {{ $t('label.tsplus.input') }}
      </div>
      <div class="csv-output">
        <h1>Output:</h1>
        {{ $t('label.tsplus.output') }}
      </div>
      <div class="csv-extra">
        <h1>Infos:</h1>
        {{ $t('label.tsplus.extra') }}
      </div>
    </div>
    <div class="csv-example">
      <a-button
      type="primary"
      style="width: 29%;"
      @click="exampledownload=true">
        {{ $t('label.tsplus.example')}}
      </a-button>
    </div>
    <a-form
    :ref="formRef"
    :model="form"
    :rules="rules"
    layout="vertical">
      <a-form-item
      ref="file"
      name="file"
      style="width: 90%; margin-left: 5%;">
        <a-upload-dragger
        :multiple="false"
        :fileList="fileList"
        :remove="handleRemove"
        :beforeUpload="beforeUpload"
        @change="handleChange"
        v-model:value="form.file"
        style="margin-top: 2%; width: 100%;">
          <p class="ant-upload-drag-icon">
            <cloud-upload-outlined />
          </p>
          <p class="ant-upload-text" v-if="fileList.length === 0">
            {{ $t('label.volume.csv.description') }}
          </p>
        </a-upload-dragger>
      </a-form-item>
      <a-button
      type="primary"
      :disabled="fileList.length === 0"
      :loading="uploading"
      style="margin-top: 1%; width: 90%; margin-left: 5%;"
      @click="handleUpload">
        {{ uploading ? 'Uploading' : 'Start Upload' }}
      </a-button>
    </a-form>
  </div>
  <a-modal
    style="top: 20px;"
    centered
    :title="$t('label.csv.example.download')"
    :closable="false"
    v-model:visible="exampledownload"
    @ok="handleDownload"
    >
      {{ $t('label.csv.example.download.confirm') }}
    </a-modal>
</template>
<script>
import api2 from '@/wpApi/api2'
import { ref, reactive } from 'vue'
import Breadcrumb from '@/components/widgets/Breadcrumb'
export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      fileList: [],
      file: [],
      uploading: false,
      exampledownload: false,
      csvFileType: ['.csv', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    }
  },
  created () {
    this.initForm()
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({})
      this.rules = reactive({
        file: [
          { required: true, message: this.$t('message.error.required.file') },
          {
            validator: this.checkCsvRulesFile
          }
        ]
      })
    },
    async checkCsvRulesFile (rule, value) {
      if (!value || value === '') {
        return Promise.resolve()
      } else {
        if (!this.csvFileType.includes(value.type)) {
          return Promise.reject(this.$t('label.validate.csv.type'))
        }

        try {
          const validFile = await this.readCsvFile(value)
          if (!validFile) {
            return Promise.reject(this.$t('label.error.rules.file.import'))
          } else {
            return Promise.resolve()
          }
        } catch (reason) {
          return Promise.reject(this.$t(reason))
        }
      }
    },
    readCsvFile (file) {
      return new Promise((resolve, reject) => {
        if (window.FileReader) {
          var reader = new FileReader()
          reader.onload = (event) => {
            this.rulesCsv = event.target.result
            var lines = this.rulesCsv.split('\n')
            var headers = lines[0].split(',')
            if (headers.length !== 1) {
              reject(this.$t('label.validate.csv.headerlength'))
            } else if (!((headers[0].trim() === 'uuid') || (headers[0].trim() === 'UUID'))) {
              reject(this.$t('label.validate.csv.headername'))
            } else {
              resolve(true)
            }
          }

          reader.onerror = (event) => {
            if (event.target.error.name === 'NotReadableError') {
              reject(event.target.error)
            }
          }

          reader.readAsText(file)
        } else {
          reject(this.$t('label.validate.csv.read'))
        }
      })
    },
    handleRemove (file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
      this.form.file = undefined
    },
    beforeUpload (file) {
      if (!this.csvFileType.includes(file.type)) {
        return false
      }
      this.fileList = [file]
      this.form.file = file
      return false
    },
    handleChange (info) {
      if (info.file.status === 'error') {
        this.$notification.error({
          message: this.$t('label.error.file.upload'),
          description: this.$t('label.error.file.upload')
        })
      }
    },
    handleUpload () {
      if (this.fileList.length > 1) {
        this.$notification.error({
          message: this.$t('message.upload.csv.onefile.header'),
          description: this.$t('message.upload.csv.onefile'),
          duration: 0
        })
      }
      this.formRef.value.validate().then(() => {
        const { fileList } = this
        const formData = new FormData()
        formData.append('file', fileList[0])
        api2.getTSPlusInformation(formData).then((json) => {
          this.$notification.success({
            message: this.$t('message.success.upload'),
            description: this.$t('message.success.upload.csv')
          })
          const fileUrl = window.URL.createObjectURL(new Blob([json.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileUrl
          fileLink.setAttribute('download', 'tsplus.csv')
          document.body.appendChild(fileLink)
          fileLink.click()
        }).catch(e => {
          this.$notification.error({
            message: this.$t('message.upload.failed'),
            description: `${this.$t('message.upload.failed')} - ${e.response.data}`,
            duration: 0
          })
        })
      })
    },
    handleDownload () {
      // download example.csv
      api2.getExampleTsPlusFile().then((json) => {
        this.$notification.success({
          message: this.$t('message.success.download.header'),
          description: this.$t('message.success.download')
        })
        const fileUrl = window.URL.createObjectURL(new Blob([json.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileUrl
        fileLink.setAttribute('download', 'example.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
      }).catch(e => {
        this.$notification.error({
          message: this.$t('message.download.failed.header'),
          description: `${this.$t('message.download.failed')} - ${e.response.data}`,
          duration: 0
        })
      })
      this.exampledownload = false
    }
  }
}
</script>
<style scoped lang="less">
.breadcrumb-card {
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -16px;
  margin-bottom: 12px;
}

.ant-breadcrumb {
  vertical-align: text-bottom;
}

.csv-info {
  height: 130px;
  width: 90%;
  margin-left: 5%;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  font-size: 16px;
}
.csv-input {
  height: 100%;
  width: 30%;
  margin-top: 0px;
}
.csv-output {
  height: 100%;
  width: 30%;
  margin-left: 5%;
  margin-top: 0px;
}
.csv-extra {
  height: 100%;
  width: 30%;
  margin-left: 5%;
  margin-top: 0px;
}
.csv-example {
  height: 30px;
  width: 90%;
  margin-left: 5%;
}
</style>
